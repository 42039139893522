<template>
    <div id="EsportsMember_config">
        <div class="EsportsMember_config_title">
            基础信息
        </div>
        <div class="EsportsMember_config_content">
            <div class="EsportsMember_config_content_item">
                <div>
                    会员日 <el-input v-model="member_date.member_day" size="small"></el-input>
                </div>
                <span>请手动输入每个月具体日期，作为会员日促进会员消费</span>
            </div>
            <div class="EsportsMember_config_content_item">
                <div>
                    有效期 <el-input v-model="member_date.member_expire" size="small"></el-input>
                </div>
                <span>会员默认12个月自定义设置，升级后有效期按升级审核完成当天作为起始日期往后顺延有效期</span>
            </div>
        </div>
        <div class="EsportsMember_config_button">
            <el-button size="small" @click="cencel">取消</el-button>
            <el-button size="small" @click="submit" type="primary">保存</el-button>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            member_date: {
                member_expire:1,
                member_day:1,
            },
        }
    },
    created() {
        this.getMemberConfig()
    },
    computed: {},
    methods: {
        // 取消
        cencel() {
            this.getMemberConfig()
        },
        //保存
        submit() {
            let data = { ...this.member_date }
            this.my_request('admin/member/config/basic', data, 'PUT').then(res => {
                console.log('保存会员配置结果', res);
                let { code } = res.data
                if (200 == code) {
                    this.$message.success('保存成功！')
                    this.getMemberConfig()
                } else {
                    this.$message.error('保存失败')
                }
            })
        },
        // 获取会员配置
        getMemberConfig() {
            this.my_request('admin/member/config').then(res => {
                console.log('获取会员配置结果', res);
                let { code, data } = res.data
                if (200 == code) {
                    this.member_date = data
                }
            })
        },
    }
}

</script>

<style lang="scss" scoped>
#EsportsMember_config {}

.EsportsMember_config_title {
    font-size: 25px;
    padding: 20px;
}

.EsportsMember_config_content {
    padding: 20px 50px;

    .EsportsMember_config_content_item {
        margin-bottom: 20px;

        .el-input {
            margin-left: 10px;
            width: 100px;
        }

        span {
            font-size: 14px;
            color: #999999;
        }
    }
}

.EsportsMember_config_button {
    width: 200px;
    margin: auto;
}
</style>